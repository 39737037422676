
import Vue from "vue";
import SpinnerModule from "@/store/modules/spinnerModule";
import SnackModule from "@/store/modules/errorSnackbar";
import SelectoresModule from "@/store/modules/selectoresModule";
import { ApiPlc, AxiosHttpClient, getUrl, ApiList } from "apd.apiconnectors";
import { HistoryLog, Kiosko } from "apd.models";
import { DataTableHeader } from "vuetify";
import { mdiHandOkay, mdiHandPointingLeft } from "@mdi/js";

export default Vue.extend({
  name: "ReportePlc",
  components: {
    SelectorCajeros: () => import("@/components/selectores/cajeros.vue"),
    Boton: () => import("@/components/botones/boton.vue"),
    DialogReportes: () => import("@/components/dialogReportes.vue"),
  },
  data() {
    return {
      validation: false as boolean,
      cajero: "" as string | string[] | null,
      titulo: "" as string,
      data: null as HistoryLog[] | null,
      icons: { ok: mdiHandOkay, notOk: mdiHandPointingLeft } as Record<string, string>,
      headersData: [
        { text: "", value: "data-table-expand" },
        {
          text: "Fecha",
          value: "timestamp",
          align: "start",
          sortable: true,
        },
        {
          text: "Cajero",
          value: "partitionKey",
          align: "start",
          sortable: true,
        },
        {
          text: "Log",
          value: "url",
          align: "start",
          sortable: true,
        },
        {
          text: "# Ops.",
          value: "operaciones",
          align: "start",
          sortable: true,
        },
        {
          text: "Excepcion",
          value: "exception",
          align: "start",
          sortable: false,
        },
        {
          text: "Inner Exception",
          value: "innerException",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          value: "icon",
          align: "center",
          sortable: false,
        },
      ] as DataTableHeader[],
    };
  },
  computed: {
    cajeros(): Kiosko[] {
      return SelectoresModule.cajeros;
    },
  },
  methods: {
    changeCajeros(data: string | string[] | null) {
      this.cajero = data;

      if (this.cajero) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
    async Visualizar(dialog: boolean, show: (value: boolean) => void) {
      await this.CargarReporte(false);

      if (this.data && this.data.length > 0) {
        show(!dialog);
      } else {
        SnackModule.Show("No se encontraron entradas de log para el cajero seleccionado.");
      }
    },
    async VisualizarErrores(dialog: boolean, show: (value: boolean) => void) {
      await this.CargarReporte(true);

      if (this.data) {
        show(!dialog);
      }
    },
    async CargarReporte(errores: boolean) {
      SpinnerModule.Show();
      const client = new AxiosHttpClient(getUrl(ApiList.PLC, this.$store.getters.appMode));
      client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const api = new ApiPlc(client);

      try {
        if (errores) {
          this.titulo = "Errores";
          this.data = (await api.HistoryLogErrores()) as HistoryLog[];
        } else {
          var cajero = this.cajeros.find((c) => c.id == this.cajero);

          if (cajero) {
            this.titulo = cajero.nombre;
          }

          this.data = (await api.HistoryLog(this.titulo)) as HistoryLog[];
        }
      } catch (e) {
        //
      } finally {
        SpinnerModule.Hide();
      }
    },
  },
});
